.contacts {
  position: relative;
  z-index: 1;
  padding: 0px;
  max-width: 1400px;
  margin: 100px auto 0 auto;
}
.contacts__map {
  position: relative;
  z-index: 0;
  width: 1400px;
  height: 736px;
  overflow: hidden;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -o-border-radius: 50px;
}
.contacts__map-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
  object-position: left;
}
.contacts__section-title {
  position: relative;
  z-index: 1;
  max-width: 625px;
  padding: 57px 0 0 0;
}
.contacts__box {
  position: absolute;
  z-index: 2;
  max-width: 640px;
  padding: 80px 20px 0 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  -webkit-box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  -moz-box-shadow: 0px 0px 100px rgba(61, 70, 98, 0.2);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 0;
  overflow: hidden;
  bottom: 82px;
  left: 120px;
}
.contacts__contact-box {
  max-width: 260px;
  margin: 0 141px 0 0;
}
.contacts__contact {
  border-bottom: 1px dashed #dadada;
  -webkit-border-bottom: 1px dashed #dadada;
  -moz-border-bottom: 1px dashed #dadada;
  -o-border-bottom: 1px dashed #dadada;
}
.contacts__contact:nth-of-type(3) {
  border: none;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
}
.contacts__title {
  max-width: 159px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 0 0 11px 0;
}
.contacts__text {
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: #3d4662;
  text-align: left;
  margin: 0 0 25px 0;
}
.contacts__tel {
  text-decoration: none;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  transition: opacity linear 1s;
}
.contacts__tel:hover {
  opacity: 0.5;
}
.contacts__gallery-box {
  max-width: 260px;
}
.contacts__gallery {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
}
.contacts__slider {
  max-width: 159px;
}
.contacts__img {
  width: 159px;
  height: 150px;
  margin: 0 0 10px 0;
  background: linear-gradient(
      180deg,
      rgba(61, 70, 98, 0) 50.17%,
      rgba(61, 70, 98, 0.7) 100%
    ),
    linear-gradient(0deg, rgba(61, 70, 98, 0.5), rgba(61, 70, 98, 0.5));
  background-blend-mode: normal, lighten, normal, normal;
  border-radius: 10px;
  cursor: pointer;
  transition: opacity linear 1s;
  object-fit: cover;
}
.map__icon {
  width: 89px;
}
.contacts__img:nth-of-type(3) {
  margin: 0 0 -75px 0;
}
.contacts__img:hover {
  opacity: 0.8;
}
.contacts__box-mess {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 25px 0 0 0;
}
.contacts__social-link {
  margin: 0 10px 0 0;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  transition: opacity linear 1s;
}
.contacts__social-link:nth-of-type(1) {
  background-image: url(../../images/icon/whatsapp.svg);
}
.contacts__social-link:nth-of-type(2) {
  background-image: url(../../images/icon/viber.svg);
}
.contacts__social-link:nth-of-type(3) {
  background-image: url(../../images/icon/telega.svg);
}
.contacts__social-link:nth-of-type(1):hover {
  opacity: 0.5;
}
.contacts__social-link:nth-of-type(2):hover {
  opacity: 0.5;
}
.contacts__social-link:nth-of-type(3):hover {
  opacity: 0.5;
}
.contacts__arrow-box {
  max-width: 60px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  margin: 0 0 0 20px;
}
.contacts__arrow {
  width: 60px;
  height: 59px;
  margin: 0 0 20px 0;
  background: none;
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  outline: none;
  -webkit-outline: none;
  -moz-outline: none;
  -o-outline: none;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  -webkit-border: none;
  -moz-border: none;
  -o-border: none;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -o-border-radius: 100px;
  transition: opacity linear 1s;
}
.contacts__arrow:hover {
  opacity: 0.5;
}
.contacts__arrow_left {
  border: 1px solid rgba(61, 70, 98, 0.2);
  -webkit-border: 1px solid rgba(61, 70, 98, 0.2);
  -moz-border: 1px solid rgba(61, 70, 98, 0.2);
  -o-border: 1px solid rgba(61, 70, 98, 0.2);
  background-image: url(../../images/icon/arrow-gray.svg);
}
.contacts__arrow_right {
  background-image: url(../../images/icon/arrow.svg);
  background-color: #6fccf5;
  border: 1px solid rgba(82, 176, 239, 0.5);
  -webkit-border: 1px solid rgba(82, 176, 239, 0.5);
  -moz-border: 1px solid rgba(82, 176, 239, 0.5);
  -o-border: 1px solid rgba(82, 176, 239, 0.5);
  box-shadow: 0px 5px 20px rgba(23, 119, 232, 0.3);
  -webkit-box-shadow: 0px 5px 20px rgba(23, 119, 232, 0.3);
  -moz-box-shadow: 0px 5px 20px rgba(23, 119, 232, 0.3);
}
.contacts__map_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(61, 70, 98, 0.5);
  opacity: 0;
  transition: opacity linear 2s;
  z-index: 1;
  cursor: pointer;
  border-radius: 50px;
}
.contacts__map_bg:hover {
  opacity: 1;
}
.contacts__map_text {
  max-width: 200px;
  font-family: "GothamPro";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;
  color: #ffffff;
  text-align: left;
  margin: 0 20px 0 0;
}
@media screen and (max-width: 1130px) {
  .contacts__box {
    max-width: 500px;
    padding: 60px 20px 0 80px;
    left: 80px;
  }
  .contacts__contact-box {
    margin: 0 41px 0 0;
  }
  .contacts__map-img {
    object-position: center;
  }
}
@media screen and (max-width: 912px) {
  .contacts__box {
    max-width: 450px;
    padding: 30px 20px 0 30px;
    left: 50px;
  }
  .contacts__contact-box {
    margin: 0 20px 0 0;
  }
  .contacts__arrow-box {
    margin: 0 0 0 5px;
  }
}
@media screen and (max-width: 768px) {
  .contacts__box {
    bottom: 50px;
  }
}
@media screen and (max-width: 600px) {
  .contacts__box {
    left: 24px;
  }
}
@media screen and (max-device-width: 768px) {
  .contacts__map_bg {
    align-items: center;
    justify-content: flex-start;
  }
  .contacts__map_text {
    max-width: 272px;
    text-align: center;
    margin: 155px 0 0 0;
  }
  .contacts__slider {
    max-height: 407px;
    overflow: scroll;
    -ms-overflow-style: none;
  }
  .contacts__slider::-webkit-scrollbar {
    display: none;
  }
  .contacts__arrow-box {
    display: none;
  }
  .contacts__img:nth-of-type(3) {
    margin: 0 0 0 0;
  }
}
@media screen and (max-width: 547px) {
  .contacts__img {
    width: 206px;
    object-fit: cover;
  }
  .contacts {
    margin: 70px 0 427px 0;
  }
  .contacts__box {
    flex-direction: column;
    width: 242px;
    padding: 30px 0 0 30px;
    bottom: -400px;
  }
  .contacts__gallery-box {
    margin: 55px 0 0 0;
  }
  .contacts__section-title {
    max-width: 500px;
  }
  .contacts__slider {
    max-width: 206px;
  }
}
